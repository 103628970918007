<template>
  <section id="saintOuen-5">
    <div class="sct-left">
      <div class="classParent" data-sect="sect1">
        <div class="sct-info ctn-align">
          <h4 class="title-h4">{{ $t("dataIntersection")["0"] }}</h4>
        </div>
        <figure class="mobMap-img">
          <img :src="$t('mobiliteDouce1')" alt="batiment-harmony-1" />
        </figure>
        <div class="sct-detail ctn-align">
          <p>{{ $t("dataIntersection")["1"] }}</p>
          <p>{{ $t("dataIntersection")["2"] }}</p>
          <p>{{ $t("dataIntersection")["3"] }}</p>
        </div>

        <div class="card-item ctn-align" v-view="firstCircleAnim">
          <div class="img-cercle">
            <figure>
              <lottie-animation class="brush-logo" ref="firstCircleAnim" :animationData=" require('@/assets/lottie/brush-cercle-saintouen.json')"
                :loop="false"
                :autoPlay="false"
                :speed="5"
              />
            </figure>
          </div>
          <div class="card-bg">
            <figure>
              <img class="loadTemp" src="" :dta-src="this.$pathprod + 'images/saint-ouen/saint-ouen-img-mask.png'" srcset="" :dta-srcset="this.$pathprod + 'images/saint-ouen/saint-ouen-img-mask.png'" alt="cercle-brush"/>
            </figure>
          </div>

          <ul class="ctn-parent">
            <li v-for="firstItem in firstItems" :key="firstItem.id" class="list-item list-item-custom">
              <div class="ctn-item">
                <div class="ctn-item-title">
                  <span class="title">{{ firstItem.title }}</span>
                  <span class="subtitle">{{$t("dataSaintlazare")[firstItem.id - 1]}}</span>
                </div>
                <div class="ctn-item-nbr">
                  <div>{{ firstItem.nbr }}</div>
                  <div>{{ firstItem.subNbr }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--********************************-->
      <div class="classParent" data-sect="sect2" id="mobilite-douce">
        <div>
          <div class="sct-info ctn-align">
            <h4 class="title-h4">{{ $t("dataIntersection")["4"] }}</h4>
          </div>

          <figure class="mobMap-img">
            <img :src="$t('mobiliteDouce2')" alt="batiment-harmony-2" />
          </figure>
          <div class="sct-detail ctn-align">
            <h2 class="title-h2">{{ $t("dataIntersection")["5"] }}</h2>
            <div>
              <div class="head-txt">{{ $t("dataIntersection")["6"] }}</div>
              <p>{{ $t("dataIntersection")["7"] }}</p>
            </div>

            <div>
              <div class="head-txt">{{ $t("dataIntersection")["8"] }}</div>
              <p>{{ $t("dataIntersection")["9"] }}</p>
            </div>

            <div>
              <div class="head-txt">{{ $t("dataIntersection")["10"] }}</div>
              <p>{{ $t("dataIntersection")["11"] }}</p>
            </div>
          </div>
        </div>

        <div class="card-item ctn-align" v-view="secondCircleAnim">
          <div class="img-cercle">
            <figure>
              <lottie-animation class="brush-logo"
                ref="secondCircleAnim"
                :animationData="require('@/assets/lottie/brush-cercle-saintouen.json')"
                :loop="false"
                :autoPlay="false"
                :speed="5"
              />
            </figure>
          </div>
          <div class="card-bg">
            <figure>
              <img class="loadTemp" src="" :dta-src="this.$pathprod + 'images/saint-ouen/saint-ouen-img-mask.png'" srcset="" :dta-srcset="this.$pathprod + 'images/saint-ouen/saint-ouen-img-mask.png'" alt="cercle-brush"/>
            </figure>
          </div>
          <div class="ctn-parent">
            <div class="card-title">{{ $t("dataIntersection")["12"] }}</div>
            <ul>
              <li class="list-item" v-for="secondItem in secondItems" :key="secondItem.id">
                <div class="ctn-item">
                  <div class="ctn-item-title">
                    <span class="title">{{ secondItem.title }}</span>
                  </div>
                  <div class="ctn-item-nbr">{{ secondItem.nbr }}'</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--********************************-->
      <div class="classParent rellax" data-sect="sect3" id="saint-ouen-vie">
        <div class="sct-info ctn-align">
          <div class="sct-detail mb-30">
            <h2 class="title-h2">{{ $t("dataIntersection")["13"] }} <br>{{ $t("dataIntersection")["14"] }}</h2>
          </div>
        </div>
        <figure class="mobMap-img">
          <img :src="$t('mobiliteDouce3')" alt="batiment-harmony-3" />
        </figure>

        <div class="sct-info sct-info-custom ctn-align">
          <h4 class="title-h4 mw-100 mb-30">
            {{ $t("dataIntersection")["15"] }}
          </h4>
          <ul class="list-custom">
            <li v-for="thirdItem in thirdItems" :key="thirdItem.id">
              <i class="ico-circle" :class="thirdItem.color"></i>
              {{ $t("dataRestauration")[thirdItem.id - 1] }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--*****************************************************-->
    <div class="sct-right">
      <div class="listMap">
        <figure class="map-harmony" data-img="sect1">
          <img :src="$t('mobiliteDouce1')" class="img-map" alt="batiment-harmony-1" />
        </figure>
        <figure class="map-harmony" data-img="sect2">
          <img :src="$t('mobiliteDouce2')" class="img-map" :dta-src="$t('mobiliteDouce2')" alt="batiment-harmony-2"/>
        </figure>
        <figure class="map-harmony" data-img="sect3">
          <img class="imgtop-pos img-map" :src="$t('mobiliteDouce3')" alt="batiment-harmony-3"/>
        </figure>
      </div>
    </div>
  </section>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "SaintOuen5",
  props: {
    msg: String,
  },
  components: {
    LottieAnimation,
  },
  data: () => ({
    observer: null,
    dataLang: false,
    firstItems: [
      { id: 1,title: "Saint Lazare",subtitle: "pour relier la QCA et rayonner avec Eole !",nbr: "7'",subNbr: null},
      { id: 2, title: "Madeleine", subtitle: "", nbr: "8'", subNbr: null},
      { id: 3, title: "Porte Maillot", subtitle: "", nbr: "8'", subNbr: null},
      { id: 4, title: "châtelet", subtitle: "", nbr: "11'", subNbr: null},
      { id: 5,title: "Invalides",subtitle: "pour changer de quais !",nbr: "25'",subNbr: null},
      { id: 6, title: "La Défense", subtitle: "", nbr: "28'", subNbr: null},
      { id: 7, title: "Gare de Lyon", subtitle: "", nbr: "13'", subNbr: null},
      { id: 8, title: "Gare du Nord", subtitle: "", nbr: "20'", subNbr: null},
      { id: 9, title: "Gare Montparnasse",subtitle: "",nbr: "29'",subNbr: null},
      { id: 10,title: "Roissy CDG TGV",subtitle: "",nbr: "43' > 25'",subNbr: "(2030)"},
      { id: 11,title: "Aéroport d'Orly",subtitle: "",nbr: "59' > 37'",subNbr: "(2024-25)"},
    ],
    secondItems: [
      { id: 1, title: "Saint Lazare", nbr: "15"},
      { id: 2, title: "Montmartre", nbr: "20"},
      { id: 3, title: "Rue de Rivoli", nbr: "22"},
      { id: 4, title: "Canal Saint-Malin", nbr: "25"},
      { id: 5, title: "République", nbr: "26"},
      { id: 6, title: "Châtelet", nbr: "27" },
    ],
    thirdItems: [
      { id: 1, title: "Commerces à venir", color: "color-red"},
      { id: 2, title: "Snacks", color: "color-lighBlue" },
      { id: 3, title: "Restaurants", color: "color-orange" },
      { id: 4, title: "Hypermarchés", color: "color-purple" },
      { id: 5, title: "Fitness", color: "color-pink" },
      { id: 6, title: "Librairie", color: "color-brown" },
      { id: 7, title: "Healthy food", color: "color-green" },
      { id: 8, title: "Galerie commerciales", color: "color-yellow"},
      { id: 9, title: "Pharmacie", color: "color-grey"},
    ],
    isVisible: true,
  }),
  methods: {
    //play animation on-show Circle
    firstCircleAnim(e) {
      if (e.type == "enter") {
        this.$refs.firstCircleAnim.play();
      }
    },
    secondCircleAnim(e) {
      if (e.type == "enter") {
        this.$refs.secondCircleAnim.play();
      }
    },
    //********** */
  },
  mounted() {
    //Observe on change animation map
    var parentEl = document.querySelectorAll(".sct-left .classParent");
    var listImg = document.querySelectorAll(".sct-right .map-harmony");

    const observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        for (let itemImg of listImg) {
          if (itemImg.getAttribute("data-img") === entry.target.getAttribute("data-sect")) {
            itemImg.classList.add("openMap");

            for (let nothis of listImg) {
              if (nothis !== itemImg) nothis.classList.remove("openMap");
            }
          }
        }
      }
    });
    for (let item of parentEl) {
      observer.observe(item);
    }
    //******************** */
  },
};
</script>
